import { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import { faTrash, faStar as faStarFilled, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { AllHtmlEntities } from 'html-entities';
import './ViewItem.css';
import { linkFinder } from '../biz/linkFinder';

const entities = new AllHtmlEntities();

function ViewItem({item, focus, itemLookupTree, close, edit, editLinks, editAliases, deleteItem, toggleArchiveItem, toggleStarItem, link, imageUrl, zoomImage}) {
  const [showText, setShowText] = useState({ __html: entities.encode(item.text) });
  
  useEffect(() => {
    const textWithLinks = linkFinder(itemLookupTree, item.text, item.id).output.replaceAll("\n", "<br>");
    setShowText({ __html: textWithLinks });
  }, [item, itemLookupTree]);

  const tryLinking = useCallback((e) => {
    if (e.target && e.target.dataset.id) {
      link(e.target.dataset.id);
    }
  }, [link]);

  const deleteCallback = useCallback(() => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      deleteItem(item);
    }
  }, [item, deleteItem]);

  return <>
    <div className="formrow namerow">
      <span className="name">{item.name}</span>
      <div className="aliasbutton" title="Name aliases" onClick={editAliases}><FontAwesomeIcon icon={faEllipsisVertical}></FontAwesomeIcon></div>
    </div>
    <div className="formrow grow view"><div tabIndex="1" dangerouslySetInnerHTML={showText} onClick={tryLinking}></div></div>
    { item.imageFileName &&
      <div className="formrow">
        <div className="image">
          <img src={imageUrl(item.imageFileName)} alt={item.name} onClick={() => zoomImage(item, imageUrl(item.imageFileName))}></img>
        </div>
      </div> }
    <div className="buttonrow reverse">
      <div className="buttoncontainer"><button onClick={editLinks}>Links</button>{item.archived || <button onClick={edit}>Edit</button>}<button onClick={close}>{focus ? "Close focus" : "Close"}</button></div>
      <div className="buttoncontainer grow">
        <button onClick={() => toggleArchiveItem(item)}>{item.archived ? "Unarchive" : "Archive"}</button>
        <button className="delete" onClick={deleteCallback}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></button>
        <button className="star" onClick={() => toggleStarItem(item)}><FontAwesomeIcon icon={item.starred ? faStarFilled : faStarOutline}></FontAwesomeIcon></button>
      </div>
    </div>
  </>;
}

export default ViewItem;