import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faMagnifyingGlass, faDownload } from '@fortawesome/free-solid-svg-icons';
import './Adventure.css';
import backend from './backend';
import SideList from './SideList';
import ItemBox from './itembox/ItemBox';
import Search from './Search';
import ImageDialog from './ImageDialog';
import { makeLookupTreeFromItems } from './biz/linkFinder';
import { isNewItemId } from './biz/itemUtil';

const itemSortingFunction = (a, b) => {
  if (!!a.archived !== !!b.archived) {
    return a.archived ? 1 : -1;
  } else if (!!a.starred !== !!b.starred) {
    return a.starred ? -1 : 1;
  } else {
    return a.name.localeCompare(b.name)
  }
};

const loadEverything = async (adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree) => {
  const response = await backend.loadAdventure(adventureId);
  if (response.ok) {
    const result = await response.json();
    setName(result.adventure.name);
    setQuests(result.quests.sort((a, b) => !!a.archived === !!b.archived ? 0 : (a.archived ? 1 : -1)));
    setPeopleAndOrgs(result.peopleAndOrgs.sort(itemSortingFunction));
    setPlaces(result.places.sort(itemSortingFunction));
    setMisc(result.misc.sort(itemSortingFunction));
    setLogEntries(result.logEntries.reverse());

    //const normalizeName = (name) => name.toLowerCase().replace(/'|:|`|´|/, "");

    setItemLookupTree(makeLookupTreeFromItems([...result.quests, ...result.peopleAndOrgs, ...result.places, ...result.misc]));
    return result;
  }

  return {};
};

let editProtection = [];

const reconstituteItemsOpen = (setItemsOpen, quests, peopleAndOrgs, places, misc, logEntries, removeItemId, extraItemId) => {
  if (removeItemId === extraItemId) {
    removeItemId = undefined;
    extraItemId = undefined;
  }
  const allItems = [...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries];
  const extraItems = (extraItemId ? [{ id: extraItemId }] : []);
  setItemsOpen((itemsOpen) => {
    const openItemsWithoutRemoved = itemsOpen.filter(item => item.id !== removeItemId);
    const updatedAndPreserved = [...openItemsWithoutRemoved, ...extraItems].map(oldItem => (!editProtection.find(id => id === oldItem.id) && allItems.find(newItem => newItem.id === oldItem.id)) || oldItem);
    return updatedAndPreserved;
  })
  return allItems;
};

function Adventure() {
  const params = useParams();
  const adventureId = params.id;
  const [name, setName] = useState("");
  const [quests, setQuests] = useState([]);
  const [peopleAndOrgs, setPeopleAndOrgs] = useState([]);
  const [places, setPlaces] = useState([]);
  const [misc, setMisc] = useState([]);
  const [logEntries, setLogEntries] = useState([]);
  const [focusType, setFocusType] = useState(null);
  const [itemsOpen, setItemsOpen] = useState([]);
  const [itemLookupTree, setItemLookupTree] = useState(new Map());
  const [imageDialogState, setImageDialogState] = useState();
  const [flashValues, setFlashValues] = useState({});
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree]);

  const addQuest = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'quest',
      name: '',
      text: '',
      links: []
    }]);
  }, [setItemsOpen]);

  const addPersonOrOrg = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'personororg',
      name: '',
      text: '',
      links: []
    }]);
  }, [setItemsOpen]);

  const addPlace = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'place',
      name: '',
      text: '',
      links: []
    }]);
  }, [setItemsOpen]);

  const addMiscItem = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'miscitem',
      name: '',
      text: '',
      links: []
    }]);
  }, [setItemsOpen]);

  const addLogEntry = useCallback(() => {
    setItemsOpen((itemsOpen) => [...itemsOpen, {
      id: '*' + Date.now(),
      type: 'logentry',
      name: '',
      text: '',
      links: []
    }]);
  }, [setItemsOpen]);

  useEffect(() => {
    const keyHandler = (e) => {
      if (e.shiftKey && (e.ctrlKey || e.metaKey)) {
        if (e.key === 'F' || e.key === "f") {
          setShowSearch(true);
          e.preventDefault();
        }
        if (e.key === 'D' || e.key === "d") {
          setItemsOpen([]);
          e.preventDefault();
        }
        if (e.key === 'U' || e.key === "u") {
          addQuest();
          e.preventDefault();
        }
        if (e.key === 'O' || e.key === "o") {
          addPersonOrOrg();
          e.preventDefault();
        }
        if (e.key === 'P' || e.key === "p") {
          addPlace();
          e.preventDefault();
        }
        if (e.key === 'M' || e.key === "m") {
          addMiscItem();
          e.preventDefault();
        }
        if (e.key === 'L' || e.key === "l") {
          addLogEntry();
          e.preventDefault();
        }
      } else if (e.keyCode === 27) {
        setShowSearch(false);
      }
    };
    window.addEventListener("keydown", keyHandler);
    return () => {
      window.removeEventListener("keydown", keyHandler);
    }
  }, [setShowSearch, setItemsOpen, addQuest, addPersonOrOrg, addPlace, addMiscItem, addLogEntry]);

  const setEditProtection = useCallback((itemId, value) => {
    if (value) {
      if (!editProtection.find(id => id === itemId)) {
        editProtection.push(itemId);
      }
    } else {
      editProtection = editProtection.filter(id => id !== itemId);
    }
  }, []);

  const saveItem = useCallback((savedItem) => {
    (async () => {
      const saveResponse = isNewItemId(savedItem.id)
        ? await backend.insertItem(adventureId, savedItem)
        : await backend.updateItem(adventureId, savedItem);
      if (!saveResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries, savedItem.id, isNewItemId(savedItem.id) ? (await saveResponse.json()).id : savedItem.id);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const openItem = useCallback((itemId) => {
    const item = [...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries].find(item => item.id === itemId);
    if (item) {
      setFlashValues((flashValues) => ({ ...flashValues, [item.id]: Date.now()}));
      setItemsOpen((itemsOpen) => {
        if (itemsOpen.find(openItem => openItem.id === itemId)) {
          return itemsOpen;
        } else {
          return [...itemsOpen, item];
        }
      });
    }
  }, [quests, peopleAndOrgs, places, misc, logEntries, setItemsOpen, setFlashValues]);

  const closeItem = useCallback((itemId) => {
    setItemsOpen((itemsOpen) => itemsOpen.filter((item) => item.id !== itemId));
  }, [setItemsOpen]);

  const deleteItem = useCallback((item) => {
    (async () => {
      const deleteResponse = await backend.deleteItem(adventureId, item.id);
      if (!deleteResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries, item.id);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const toggleArchiveItem = useCallback((item) => {
    (async () => {
      const archiveResponse = await backend.archiveItem(adventureId, item.id, !item.archived);
      if (!archiveResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const toggleStarItem = useCallback((item) => {
    (async () => {
      const starResponse = await backend.starItem(adventureId, item.id, !item.starred);
      if (!starResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const uploadImage = useCallback(async (imageUploadFile) => {
    const imageUploadResult = await backend.uploadImage(imageUploadFile);
    if (!imageUploadResult.ok) {
      console.log("Failed to upload image");
      return undefined;
    }
    return (await imageUploadResult.json()).fileName;
  }, []);

  const addLink = useCallback((from, to) => {
    (async () => {
      const linkResponse = await backend.link(adventureId, from, to);
      if (!linkResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const removeLink = useCallback((from, to) => {
    (async () => {
      const unlinkResponse = await backend.unlink(adventureId, from, to);
      if (!unlinkResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const addAlias = useCallback((itemId, alias) => {
    (async () => {
      const aliasResponse = await backend.addAlias(adventureId, itemId, alias);
      if (!aliasResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const removeAlias = useCallback((itemId, index) => {
    (async () => {
      const removeResponse = await backend.removeAlias(adventureId, itemId, index);
      if (!removeResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries);
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen]);

  const addMarker = useCallback((itemId, toItemId, name, x, y) => {
    (async () => {
      if (toItemId) {
        const unlinkResponse = await backend.unlink(adventureId, itemId, toItemId);
        if (!unlinkResponse.ok) {
          return;
        }

        const linkResponse = await backend.link(adventureId, itemId, toItemId);
        if (!linkResponse.ok) {
          return;
        }
      }
      
      const setCoordResponse = await backend.addMarker(adventureId, itemId, toItemId, name, x, y);
      if (!setCoordResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      const newAllItems = reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries);
      const updatedImageItem = newAllItems.find(i => i.id === imageDialogState.item.id);
      setImageDialogState({ item: updatedImageItem, imageUrl: imageDialogState.imageUrl });
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen, imageDialogState, setImageDialogState]);

  const removeMarker = useCallback((itemId, linkItemId) => {
    (async () => {
      const linkResponse = await backend.removeMarker(adventureId, itemId, linkItemId);
      if (!linkResponse.ok) {
        return;
      }
      const result = await loadEverything(adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree);
      const newAllItems = reconstituteItemsOpen(setItemsOpen, result.quests, result.peopleAndOrgs, result.places, result.misc, result.logEntries);
      const updatedImageItem = newAllItems.find(i => i.id === imageDialogState.item.id);
      setImageDialogState({ item: updatedImageItem, imageUrl: imageDialogState.imageUrl });
    })();
  }, [adventureId, setName, setQuests, setPeopleAndOrgs, setPlaces, setMisc, setLogEntries, setItemLookupTree, setItemsOpen, imageDialogState, setImageDialogState]);

  const exportData = useCallback(() => {
    (async () => {
      const format = new Intl.DateTimeFormat('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false // 24-hour format
      });

      const exportResponse = await backend.fetchDataExport(adventureId);
      if (exportResponse.ok) {
        const objectUrl = window.URL.createObjectURL(await exportResponse.blob());
        const link = document.createElement('a');
        link.display = 'none';
        link.href = objectUrl;
        link.download = `QuestQuill ${name} - ${format.format(new Date()).replace(/:/g, ' ')}.json`;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    })();
  }, [adventureId, name]);

  return <div id="adventurepage">
    <div id="sidemenu">
      { (!focusType || focusType === 'quests') && <SideList heading="Quests" extraClass="quests" isFocused={focusType === 'quests'} addClick={addQuest} selectClick={item => openItem(item.id)} items={quests} showProperty="name" focus={() => setFocusType(!focusType && "quests")} /> }
      { (!focusType || focusType === 'peopleandorgs') && <SideList heading="People & Orgs" extraClass="peopleandorgs" isFocused={focusType === 'peopleandorgs'} addClick={addPersonOrOrg} selectClick={item => openItem(item.id)} items={peopleAndOrgs} showProperty="name" focus={() => setFocusType(!focusType && "peopleandorgs")} /> }
      { (!focusType || focusType === 'places') && <SideList heading="Places" extraClass="places" isFocused={focusType === 'places'} addClick={addPlace} selectClick={item => openItem(item.id)} items={places} showProperty="name" focus={() => setFocusType(!focusType && "places")} /> }
      { (!focusType || focusType === 'misc') && <SideList heading="Miscellaneous" extraClass="misc" isFocused={focusType === 'misc'} addClick={addMiscItem} selectClick={item => openItem(item.id)} items={misc} showProperty="name" focus={() => setFocusType(!focusType && "misc")} /> }
      { (!focusType || focusType === 'logentries') && <SideList heading="Log" extraClass="logentries" isFocused={focusType === 'logentries'} addClick={addLogEntry} selectClick={item => openItem(item.id)} items={logEntries} showProperty="name" focus={() => setFocusType(!focusType && "logentries")} /> }
    </div>
    <div id="mainarea">
      <div id="topbar">
        <div id="adventurename">QuestQuill: {name}</div>
        <div id="toolbar">
          <div className="toolbarbutton" title="Clear workspace"><FontAwesomeIcon icon={faEraser} onClick={() => setItemsOpen(itemsOpen.filter(item => editProtection.find(id => id === item.id)))} /></div>
          <div className="toolbarbutton" title="Search"><FontAwesomeIcon icon={faMagnifyingGlass} onClick={() => setShowSearch(true)} /></div>
          <div className="toolbarbutton" title="Download"><FontAwesomeIcon icon={faDownload} onClick={() => exportData()} /></div>
        </div>
      </div>
      {
        itemsOpen.map(item =>
          <ItemBox
            key={item.id}
            item={item}
            itemLookupTree={itemLookupTree}
            cancel={closeItem}
            save={saveItem}
            setEditProtection={setEditProtection}
            deleteItem={deleteItem}
            toggleArchiveItem={toggleArchiveItem}
            toggleStarItem={toggleStarItem}
            link={openItem}
            addLink={addLink}
            removeLink={removeLink}
            addAlias={addAlias}
            removeAlias={removeAlias}
            uploadImage={uploadImage}
            imageUrl={(name) => backend.getImageUrl(name)}
            zoomImage={(item, imageUrl) => { setImageDialogState({ item, imageUrl }) }}
            flash={flashValues[item.id]}
            allItems={[...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries]}>
          </ItemBox>) }
    </div>
    { imageDialogState && <ImageDialog item={imageDialogState.item} allItems={[...quests, ...peopleAndOrgs, ...places, ...misc, ...logEntries]} imageFileUrl={imageDialogState.imageUrl} openItem={(id) => { openItem(id); }} close={() => setImageDialogState(undefined)} addMarker={addMarker} removeMarker={removeMarker}></ImageDialog> }
    { showSearch && <Search quests={quests} peopleAndOrgs={peopleAndOrgs} places={places} misc={misc} logEntries={logEntries} openItem={(item) => { openItem(item.id); }} close={() => setShowSearch(false)}></Search> }
  </div>
}

export default Adventure;